import { InjectionToken } from '@angular/core'

export const USE_AUTOMATIC_REDIRECTION = new InjectionToken<boolean>(
  'USE_AUTOMATIC_REDIRECTION',
  {
    providedIn: 'root',
    factory: () => false
  }
)

export function withInvoiceDetailAutomaticNavigation() {
  return {
    provide: USE_AUTOMATIC_REDIRECTION,
    useValue: true
  }
}
