import { InjectionToken, inject } from '@angular/core'

export const ABSOLUTE_FEATURE_URL = new InjectionToken<string>(
  'ABSOLUTE_FEATURE_URL'
)

export function provideAbsoluteFeatureUrl(url: string) {
  return {
    provide: ABSOLUTE_FEATURE_URL,
    useValue: url
  }
}

export function injectAbsoluteFeatureUrl() {
  return inject(ABSOLUTE_FEATURE_URL)
}
