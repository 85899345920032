import { InjectionToken } from '@angular/core'

export const API_URL = new InjectionToken<string>('API_URL')
export const API_URL_NO_DEFAULT_VERSION = new InjectionToken<string>(
  'API_URL_NO_DEFAULT_VERSION'
)
export const API_URL_V1_1_VERSION = new InjectionToken<string>(
  'API_URL_V1_1_VERSION'
)
export const API_URL_V1_2_VERSION = new InjectionToken<string>(
  'API_URL_V1_2_VERSION'
)
export const API_URL_V2_VERSION = new InjectionToken<string>(
  'API_URL_V2_VERSION'
)
export const API_URL_V2_1_VERSION = new InjectionToken<string>(
  'API_URL_V2_1_VERSION'
)
