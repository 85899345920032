import { InjectionToken } from '@angular/core'

export const BREADCRUMBS = new InjectionToken<string[]>('BREADCRUMBS')

export function provideBreadcrumbs(path: string[]) {
  return {
    provide: BREADCRUMBS,
    useValue: path
  }
}
